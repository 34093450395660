@import '../../scss/theme';
@import '../../scss/layout';


.app {
  text-align: center;
  background-color: var(--background-color);
  width: 100%;

  section{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10rem 8rem 10rem;
  }
}

@media (max-width: $breakpoint-tablet) {
  .app {
    section {
      margin: 8rem 3rem;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .app {
    section {
      margin: 3rem 1rem;
    }
  }
}