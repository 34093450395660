@import '../../scss/layout.scss';
@import '../../scss/theme.scss';

div.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  color: var(--primary-text-color);
  padding: 2rem;
  font-size: 1.5rem;
  width: 100%;
  z-index: 1000;

  .footer_links{
    display: flex;
    justify-content: center;
    a img {
      width: 2rem;
      height: 2rem;
      margin: 0 1rem;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

// media queries for mobile

@media (max-width: 600px) {
  div.footer {
    font-size: 1rem;
    padding: 1rem;
    justify-content: unset;

    .footer_links {
      a img {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0.5rem;
      }
    }
  }
}