@import '../../scss/animationButtonNavBar';
@import '../../scss/theme';
@import '../../scss/layout';

button.lang-button {
  border: none;
  cursor: pointer;
  color: var(--primary-text-color);
  background-color: transparent;
  transition: background-color 0.3s ease;

  &:hover {
    color: var(--secondary-text-color);
  }

  &.active {
    font-size: 1.2rem;
  }

  &.active::after {
    content: "";
    display: block;
    width: 100%;
    height: .2rem;
    margin-top: 0.2rem;
    background: var(--primary-colored-text);
  }
}