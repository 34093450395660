@import '../../scss/theme.scss';
@import '../../scss/layout.scss';

section.educations {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;

  .educations_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem; // space between educations
  }
}
