@import '../../scss/theme';
@import '../../scss/layout';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: var(--background-color);
  padding: 2rem;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
  text-align: center;
  position: relative;

  h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: var(--primary-colored-text);
  }

  p {
    margin-bottom: 1rem;
    font-size: 0.9rem;
    color: var(--secondary-colored-text);
  }

  form {
    display: flex;
    gap: 1rem;

    input[type="email"] {
      padding: 0.8rem;
      border-radius: 4px;
      border: 1px solid var(--primary-colored-text);
    }

    .btn {
      padding: 0.8rem;
      background: var(--primary-colored-text);
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background 0.3s ease;

      &:hover {
        background: var(--primary-colored-text-darker);
      }

      &:disabled {
        background: var(--primary-colored-text-lighter);
        cursor: not-allowed;
      }
    }
  }

  .message {
    margin-top: 1rem;
    color: green;
  }

  .close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--primary-colored-text);
  }
}
