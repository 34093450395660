@import '../../scss/layout.scss';
@import '../../scss/theme.scss';

.flash-message {
  position: fixed;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  background-color: var(--background-color);
  color: var(--primary-text-color);
  border: 1px solid var(--primary-text-color);
  font-size: 1rem;
  border-radius: 1rem;
  text-align: center;
  z-index: 9999;

  &.hide {
    opacity: 0;
    transform: translateY(-100%);
  }
}
