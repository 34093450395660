@import '../../../scss/theme';
@import '../../../scss/layout';

article {
  display: flex;
  align-items: center;
  gap: 0.5rem;


  svg {
    color: var(--primary-colored-text);
    width: 2rem;
    cursor: default;
  }
}


@media (max-width: $breakpoint-tablet) {
  article {
    svg {
      width: 1.5rem;
    }
  }
}