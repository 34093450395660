@import '../../scss/theme';
@import '../../scss/layout';

section.landing_page{
  height: 83vh;

  display: flex;
  justify-content: space-around;

  .container_pic{
    margin-right: 6rem;
    img {
      width: 32rem;
      height: auto;
      border-radius: 50%;
    }
  }

  .container_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    .container_btn{
      display: flex;
      gap: 2rem;

      a{
        svg{
          width: 4rem;
          height: 4rem;
          color: var(--primary-colored-text);

          &:hover{
            color: var(--primary-colored-text-darker);
            scale: 1.1;
          }
        }
      }

    }

  }
}

@media (max-width: $breakpoint-desktop-medium) {
  section.landing_page {
    flex-direction: column;
    height: 100%;
    .container_pic {
      img {
        width: 24rem;
        margin-top: 2rem;
      }
    }

    .container_text {
      .container_btn {
        gap: 1rem;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  section.landing_page {
    .container_pic {
      margin-right: 0;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}