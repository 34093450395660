$breakpoint-desktop-large: 1640px;
$breakpoint-desktop-medium: 1240px;
$breakpoint-tablet: 1000px;
$breakpoint-mobile: 700px;
$breakpoint-mobile-small: 500px;


* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  width: 100%;
}

html {
  scroll-behavior: smooth;
}