@import '../../../scss/theme';
@import '../../../scss/layout';

form {
    display: flex;
    flex-direction: column;
    background-color: var(--background-color);
    width: 650px;
    gap: 1rem;

    input, textarea {
        display: flex;
        padding: 0.75rem;
        border: 2px solid var(--primary-colored-text); // Use a suitable color from your theme
        border-radius: 5px;
        font-size: 1rem;
        background-color: var(--background-color); // Replace with color from theme
        color: var(--primary-text-color);
        outline: none;

        &::placeholder {
            color: var(--primary-text-color); // Style placeholder text from theme
        }
        &:focus {
            border-color: var(--primary-colored-text-darker); // Style focus state from theme
            scale: 1.05;
        }
    }

    textarea {
        min-height: 150px;
        resize: vertical;
    }

    .form-inputs {
        display: flex;
        flex-direction: row;
        input {
            flex: 1;

            &:first-child {
                margin-right: 1rem;
            }
        }

    }

    @media (max-width: 1000px) {
        .form-inputs {
            flex-direction: column;
            input {
                flex: 1;

                &:first-child {
                    margin-right: 0;
                    margin-bottom: 1rem;
                }
            }

        }
    }

    input[type="submit"] {
        width: fit-content;
        transition: background-color 0.3s ease;
    }

    p {
        color: var(--primary-text-color); // Error or success message color from theme
        font-size: 0.9rem;
    }
}

@media (max-width: $breakpoint-mobile) {
    form {
        width: 100%;
        gap: 1rem;
    }
}
