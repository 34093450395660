@import '../../scss/theme';
@import '../../scss/layout';

section.projects{
  display: flex;
  flex-direction: column;
  .projects_container{
    display: flex;
    flex-direction: column;
    gap: 4rem;
    .project_cards{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      gap: 4rem;
    }
  }
}