@import '../../../scss/layout';
@import '../../../scss/theme';

.project_card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  gap: 2rem;
  border: 2px solid var(--primary-colored-text);
  border-radius: 2rem;
  padding: 1.5rem;
  transition: transform 0.3s ease;
  -webkit-transition: transform 0.3s ease;

  &:hover{
    transform: scale(1.05) rotate(2deg) translateX(1rem);
    border-color: var(--primary-colored-text-darker);
  }

  img.project-pic{
    height: 18rem;
    width: auto;
    object-fit: cover;
    border-radius: 1rem;
  }
  .buttons_container{
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
}

// media queries for mobile remove hover effect

@media (max-width: $breakpoint-mobile) {
  .project_card{
    gap: 1rem;
    padding: 1rem;
    &:hover{
      transform: unset;
      border-color: var(--primary-colored-text);
    }

    img.project-pic{
      height: 12rem;
    }

    .buttons_container {
      gap: 0.5rem;
    }
  }
}

@media (max-width: $breakpoint-mobile-small) {
  .project_card{
    img.project-pic{
      height: 10rem;
    }
  }
}

