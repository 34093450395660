@import '../../scss/layout.scss';
@import '../../scss/theme.scss';

.detail_card {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .detail_card_header {
    display: flex;
    flex-direction: column;
    .title {
      color: var(--primary-colored-text);
      font-size: 2rem;
      font-weight: bold;
      text-align: left;
    }
    .details {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 1rem;

      .details_text {
        font-size: 1.2rem;
        color: var(--secondary-text-color);
      }

      .vertical_line {
        display: block;
        width: .2rem;
        height: auto;
        background-color: var(--primary-colored-text);
      }

    }
  }
  ul.detail_card_description {
    max-width: 800px;
    padding-top: 1.3rem;
    li {
      text-align: left;
      font-size: 1.2rem;
      // go to the next line if the text is too long
      white-space: pre-wrap;
      line-height: 1.4;
      padding-bottom: .5rem;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .detail_card {
    .detail_card_header {
      .title {
        font-size: 1.5rem;
      }
      .details {
        flex-direction: column;
        align-items: flex-start;
        gap: .3rem;
        .vertical_line {
          display: none;
        }
        .details_text {
          font-size: 1.2rem;
        }
      }
    }
  }
}